var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "contact-form" },
    [
      _c("h5", { staticClass: "text-uppercase flex-grow-1" }, [
        _vm._v(_vm._s(_vm.$t("contactForm.title")))
      ]),
      _c(
        "v-form",
        {
          key: _vm.key,
          ref: "form",
          staticClass: "d-flex flex-wrap",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.sendMesssage.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("contactForm.form.name")))
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      placeholder: `${_vm.$t("contactForm.form.name")}`,
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      "hide-details": "auto",
                      height: "50",
                      rules: [_vm.requiredRules()]
                    },
                    model: {
                      value: _vm.data.name,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "name", $$v)
                      },
                      expression: "data.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("contactForm.form.email")))
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      placeholder: `${_vm.$t("contactForm.form.email")}`,
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      "hide-details": "auto",
                      height: "50",
                      rules: [_vm.requiredRules()]
                    },
                    model: {
                      value: _vm.data.email,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "email", $$v)
                      },
                      expression: "data.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("contactForm.form.fidelityCard")))
                  ]),
                  _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      placeholder: `${_vm.$t("contactForm.form.fidelityCard")}`,
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      "hide-details": "auto",
                      height: "50"
                    },
                    model: {
                      value: _vm.data.card,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "card", $$v)
                      },
                      expression: "data.card"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("contactForm.form.message")))
                  ]),
                  _c("v-textarea", {
                    staticClass: "mt-1",
                    attrs: {
                      placeholder: `${_vm.$t(
                        "contactForm.form.messagePlaceholder"
                      )}`,
                      outlined: "",
                      rounded: "",
                      dense: "",
                      type: "text",
                      "hide-details": "auto",
                      "no-resize": "",
                      "auto-grow": "",
                      rules: [_vm.requiredRules()]
                    },
                    model: {
                      value: _vm.data.message,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "message", $$v)
                      },
                      expression: "data.message"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("i18n", {
                    staticClass: "pre-wrap mt-2 text-body-2",
                    attrs: { path: "contactForm.privacyInfo", tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "link",
                        fn: function() {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "text-decoration-underline",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.handleRedirect(
                                      "https://www.galasupermercati.it/privacy-parla-con-noi"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("contactForm.privacyLink"))
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "0", md: "6" } }),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("ResponseMessage", {
                    staticClass: "mt-3",
                    attrs: { response: _vm.responseMessage }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-body-0",
                      attrs: {
                        color: "primary",
                        type: "submit",
                        "x-large": "",
                        depressed: "",
                        rounded: "",
                        block: "",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("contactForm.form.send")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }